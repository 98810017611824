export const QUEST_STATUS = {
  DRAFT: 'draft',
  IN_REVIEW: 'in review',
  PENDING: 'pending', // quest that was applied by a helper but not awarded yet
  ACTIVE: 'active', // deprecated. using 'in progress' instead
  INPROGRESS: 'in progress', // quest that was awarded but not completed
  POSTED: 'posted', // quest that is approved by not awarded
  DISAPPROVED: 'disapproved',
  EXPIRED: 'expired',
  COMPLETED: 'completed',
  COMPLETED_BY_FAMILY: 'completed by family',
  COMPLETED_BY_HELPER: 'completed by helper',
  CANCELLED: 'cancelled'
}
